import { useAuthentication } from "../providers";
import { AclId } from "../data-list";

export interface UseAcl {
  aclCheck: (aclId: AclId) => boolean;
}

export const useAcl = (): UseAcl => {
  const { authUser } = useAuthentication();

  const aclCheck = (aclId: AclId): boolean =>
    authUser?.acls.some((acl) => acl === aclId) || false;

  return { aclCheck };
};
