/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Domain = {
    Findalocaltour: 'findalocaltour',
    Airportexpresslima: 'airportexpresslima',
    Huacapucllanamiraflores: 'huacapucllanamiraflores',
    Inkaexpress: 'inkaexpress',
    Luchitoscookingclass: 'luchitoscookingclass',
    Rainbowmountaintravels: 'rainbowmountaintravels',
    Yapaexplorers: 'yapaexplorers',
    Findlocaltrips: 'findlocaltrips',
    Machupicchuhow: 'machupicchuhow',
    Escapefromlima: 'escapefromlima',
    Tourpit: 'tourpit'
} as const;
export type Domain = typeof Domain[keyof typeof Domain];


export function DomainFromJSON(json: any): Domain {
    return DomainFromJSONTyped(json, false);
}

export function DomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domain {
    return json as Domain;
}

export function DomainToJSON(value?: Domain | null): any {
    return value as any;
}

