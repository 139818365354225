/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TravelerWithReservation } from './TravelerWithReservation';
import {
    TravelerWithReservationFromJSON,
    TravelerWithReservationFromJSONTyped,
    TravelerWithReservationToJSON,
} from './TravelerWithReservation';
import type { TravelersGroupByExtra } from './TravelersGroupByExtra';
import {
    TravelersGroupByExtraFromJSON,
    TravelersGroupByExtraFromJSONTyped,
    TravelersGroupByExtraToJSON,
} from './TravelersGroupByExtra';

/**
 * 
 * @export
 * @interface TravelersList
 */
export interface TravelersList {
    /**
     * 
     * @type {string}
     * @memberof TravelersList
     */
    tourStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof TravelersList
     */
    tourId: string;
    /**
     * 
     * @type {string}
     * @memberof TravelersList
     */
    optionName: string;
    /**
     * 
     * @type {string}
     * @memberof TravelersList
     */
    optionTime: string;
    /**
     * 
     * @type {Array<TravelerWithReservation>}
     * @memberof TravelersList
     */
    travelersWithReservations: Array<TravelerWithReservation>;
    /**
     * 
     * @type {Array<TravelersGroupByExtra>}
     * @memberof TravelersList
     */
    travelersGroupByExtras: Array<TravelersGroupByExtra>;
}

/**
 * Check if a given object implements the TravelersList interface.
 */
export function instanceOfTravelersList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tourStartDate" in value;
    isInstance = isInstance && "tourId" in value;
    isInstance = isInstance && "optionName" in value;
    isInstance = isInstance && "optionTime" in value;
    isInstance = isInstance && "travelersWithReservations" in value;
    isInstance = isInstance && "travelersGroupByExtras" in value;

    return isInstance;
}

export function TravelersListFromJSON(json: any): TravelersList {
    return TravelersListFromJSONTyped(json, false);
}

export function TravelersListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelersList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tourStartDate': json['tourStartDate'],
        'tourId': json['tourId'],
        'optionName': json['optionName'],
        'optionTime': json['optionTime'],
        'travelersWithReservations': ((json['travelersWithReservations'] as Array<any>).map(TravelerWithReservationFromJSON)),
        'travelersGroupByExtras': ((json['travelersGroupByExtras'] as Array<any>).map(TravelersGroupByExtraFromJSON)),
    };
}

export function TravelersListToJSON(value?: TravelersList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tourStartDate': value.tourStartDate,
        'tourId': value.tourId,
        'optionName': value.optionName,
        'optionTime': value.optionTime,
        'travelersWithReservations': ((value.travelersWithReservations as Array<any>).map(TravelerWithReservationToJSON)),
        'travelersGroupByExtras': ((value.travelersGroupByExtras as Array<any>).map(TravelersGroupByExtraToJSON)),
    };
}

