/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LanguageSearch,
  TourSearchResult,
} from '../models';
import {
    LanguageSearchFromJSON,
    LanguageSearchToJSON,
    TourSearchResultFromJSON,
    TourSearchResultToJSON,
} from '../models';

export interface GetSearchIndexRequest {
    language: string;
}

export interface GetSearchToursRequest {
    tourIds: Array<string>;
    language: string;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     */
    async getSearchIndexRaw(requestParameters: GetSearchIndexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LanguageSearch>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getSearchIndex.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/search-index/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LanguageSearchFromJSON));
    }

    /**
     */
    async getSearchIndex(requestParameters: GetSearchIndexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LanguageSearch>> {
        const response = await this.getSearchIndexRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSearchToursRaw(requestParameters: GetSearchToursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TourSearchResult>>> {
        if (requestParameters.tourIds === null || requestParameters.tourIds === undefined) {
            throw new runtime.RequiredError('tourIds','Required parameter requestParameters.tourIds was null or undefined when calling getSearchTours.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getSearchTours.');
        }

        const queryParameters: any = {};

        if (requestParameters.tourIds) {
            queryParameters['tourIds'] = requestParameters.tourIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/search-index/tours/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TourSearchResultFromJSON));
    }

    /**
     */
    async getSearchTours(requestParameters: GetSearchToursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TourSearchResult>> {
        const response = await this.getSearchToursRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
