/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LanguageCode } from './LanguageCode';
import {
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';
import type { PartialRecordAgeIdNumber } from './PartialRecordAgeIdNumber';
import {
    PartialRecordAgeIdNumberFromJSON,
    PartialRecordAgeIdNumberFromJSONTyped,
    PartialRecordAgeIdNumberToJSON,
} from './PartialRecordAgeIdNumber';
import type { ReservationUtm } from './ReservationUtm';
import {
    ReservationUtmFromJSON,
    ReservationUtmFromJSONTyped,
    ReservationUtmToJSON,
} from './ReservationUtm';

/**
 * 
 * @export
 * @interface PostPartialReservationBody
 */
export interface PostPartialReservationBody {
    /**
     * 
     * @type {PartialRecordAgeIdNumber}
     * @memberof PostPartialReservationBody
     */
    tourTravelersCount: PartialRecordAgeIdNumber;
    /**
     * 
     * @type {string}
     * @memberof PostPartialReservationBody
     */
    tourStartDate: string;
    /**
     * 
     * @type {LanguageCode}
     * @memberof PostPartialReservationBody
     */
    languageCode: LanguageCode;
    /**
     * 
     * @type {string}
     * @memberof PostPartialReservationBody
     */
    tourId: string;
    /**
     * 
     * @type {string}
     * @memberof PostPartialReservationBody
     */
    optionId: string;
    /**
     * 
     * @type {string}
     * @memberof PostPartialReservationBody
     */
    time: string;
    /**
     * 
     * @type {ReservationUtm}
     * @memberof PostPartialReservationBody
     */
    utm: ReservationUtm | null;
    /**
     * 
     * @type {string}
     * @memberof PostPartialReservationBody
     */
    officeId?: string;
}

/**
 * Check if a given object implements the PostPartialReservationBody interface.
 */
export function instanceOfPostPartialReservationBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tourTravelersCount" in value;
    isInstance = isInstance && "tourStartDate" in value;
    isInstance = isInstance && "languageCode" in value;
    isInstance = isInstance && "tourId" in value;
    isInstance = isInstance && "optionId" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "utm" in value;

    return isInstance;
}

export function PostPartialReservationBodyFromJSON(json: any): PostPartialReservationBody {
    return PostPartialReservationBodyFromJSONTyped(json, false);
}

export function PostPartialReservationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPartialReservationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tourTravelersCount': PartialRecordAgeIdNumberFromJSON(json['tourTravelersCount']),
        'tourStartDate': json['tourStartDate'],
        'languageCode': LanguageCodeFromJSON(json['languageCode']),
        'tourId': json['tourId'],
        'optionId': json['optionId'],
        'time': json['time'],
        'utm': ReservationUtmFromJSON(json['utm']),
        'officeId': !exists(json, 'officeId') ? undefined : json['officeId'],
    };
}

export function PostPartialReservationBodyToJSON(value?: PostPartialReservationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tourTravelersCount': PartialRecordAgeIdNumberToJSON(value.tourTravelersCount),
        'tourStartDate': value.tourStartDate,
        'languageCode': LanguageCodeToJSON(value.languageCode),
        'tourId': value.tourId,
        'optionId': value.optionId,
        'time': value.time,
        'utm': ReservationUtmToJSON(value.utm),
        'officeId': value.officeId,
    };
}

