import React, { useState } from "react";
import { Select as AntSelect, SelectProps as AntdSelectProps } from "antd";
import { ComponentContainer } from "./ComponentContainer";
import { cloneDeep, isString, toString } from "lodash";
import { useTheme } from "styled-components";

export type SelectValue = string;

export type SelectFilterOption = (
  inputValue: SelectValue,
  optionLabel: string
) => boolean;

type SelectOnChange = (value?: SelectValue) => void;

interface SelectOption {
  label: string;
  value: SelectValue;
  disabled?: boolean;
}

export interface SelectProps extends Partial<_SelectProps> {
  required?: boolean;
  label?: string;
  variant?: "outlined" | "filled";
  hidden?: boolean;
  allowClear?: boolean;
  children?: React.ReactNode;
  mode?: "multiple" | "tags";
  dataTestId?: string;
  loading?: boolean;
}

interface _SelectProps {
  disabled: boolean;
  error: boolean;
  helperText: string;
  filterOption: SelectFilterOption;
  onChange: SelectOnChange;
  options: SelectOption[] | AntdSelectProps["options"];
  placeholder: string;
  autoFocus?: boolean;
  value?: SelectValue | string[];
}

const defaultFilterOption: SelectFilterOption = (inputValue, optionLabel) => {
  return optionLabel.toUpperCase().includes(inputValue.toUpperCase());
};

export const Select = ({
  value,
  placeholder = "",
  required = false,
  disabled = false,
  error = false,
  hidden = false,
  helperText,
  mode,
  label,
  children,
  onChange = (value) => value,
  variant = "outlined",
  allowClear,
  dataTestId,
  loading,
  autoFocus,
  options,
  filterOption = (inputValue, optionLabel) =>
    defaultFilterOption(inputValue, optionLabel),
}: SelectProps): JSX.Element => {
  const [search, setSearch] = useState<string>("");

  const theme = useTheme();

  const Container = ComponentContainer[variant];

  const fixValue = value ? value : undefined;

  const onSelectChange: AntdSelectProps["onChange"] = (selectValue) => {
    onChange(
      typeof selectValue === "string" ? toString(selectValue) : selectValue
    );
  };

  const selectFilterOption: AntdSelectProps["filterOption"] = (
    inputValue,
    option
  ) => {
    let filterText = "";

    if (isString(option?.label)) filterText = option?.label;
    if (isString(option?.children)) filterText = option?.children;

    return filterOption(inputValue, filterText);
  };

  const filterOptions = cloneDeep(options)
    ?.map((option) => {
      if ("options" in option)
        option.options = option.options?.filter(
          (option: Record<string, string>) =>
            search ? filterOption(search, option.label) : true
        );

      return option;
    })
    .filter((option) => ("options" in option ? option.options.length : true));

  return (
    <Container
      required={required}
      hidden={hidden}
      value={fixValue}
      error={error}
      helperText={helperText}
      disabled={disabled}
      label={label}
      dataTestId={dataTestId}
    >
      <AntSelect
        mode={mode}
        allowClear={disabled ? false : allowClear}
        bordered={false}
        disabled={disabled}
        value={fixValue}
        onChange={onSelectChange}
        onBlur={() => setSearch("")}
        onSelect={() => setSearch("")}
        onDeselect={() => setSearch("")}
        onFocus={() => setSearch("")}
        filterOption={selectFilterOption}
        showSearch
        size="large"
        style={{
          minHeight: theme.antd.height.large,
        }}
        options={filterOptions}
        onSearch={setSearch}
        placeholder={placeholder}
        autoFocus={autoFocus}
        loading={loading}
        // @ts-ignore
        autoComplete="chrome-off"
      >
        {children}
      </AntSelect>
    </Container>
  );
};
