/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { CutOffTime } from './CutOffTime';
import {
    CutOffTimeFromJSON,
    CutOffTimeFromJSONTyped,
    CutOffTimeToJSON,
} from './CutOffTime';
import type { Extra } from './Extra';
import {
    ExtraFromJSON,
    ExtraFromJSONTyped,
    ExtraToJSON,
} from './Extra';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { PartialTourDropOff } from './PartialTourDropOff';
import {
    PartialTourDropOffFromJSON,
    PartialTourDropOffFromJSONTyped,
    PartialTourDropOffToJSON,
} from './PartialTourDropOff';
import type { PartialTourMeeting } from './PartialTourMeeting';
import {
    PartialTourMeetingFromJSON,
    PartialTourMeetingFromJSONTyped,
    PartialTourMeetingToJSON,
} from './PartialTourMeeting';
import type { ProductContent } from './ProductContent';
import {
    ProductContentFromJSON,
    ProductContentFromJSONTyped,
    ProductContentToJSON,
} from './ProductContent';
import type { SelfService } from './SelfService';
import {
    SelfServiceFromJSON,
    SelfServiceFromJSONTyped,
    SelfServiceToJSON,
} from './SelfService';
import type { TravelersFields } from './TravelersFields';
import {
    TravelersFieldsFromJSON,
    TravelersFieldsFromJSONTyped,
    TravelersFieldsToJSON,
} from './TravelersFields';

/**
 * Make all properties in T optional
 * @export
 * @interface PartialTour
 */
export interface PartialTour {
    /**
     * 
     * @type {string}
     * @memberof PartialTour
     */
    operatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTour
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialTour
     */
    active?: boolean;
    /**
     * 
     * @type {PartialTourMeeting}
     * @memberof PartialTour
     */
    meeting?: PartialTourMeeting;
    /**
     * 
     * @type {PartialTourDropOff}
     * @memberof PartialTour
     */
    dropOff?: PartialTourDropOff | null;
    /**
     * 
     * @type {ProductContent}
     * @memberof PartialTour
     */
    productContent?: ProductContent;
    /**
     * 
     * @type {Array<Option>}
     * @memberof PartialTour
     */
    options?: Array<Option>;
    /**
     * 
     * @type {TravelersFields}
     * @memberof PartialTour
     */
    travelersFields?: TravelersFields;
    /**
     * 
     * @type {number}
     * @memberof PartialTour
     */
    maxTravelersPerTour?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialTour
     */
    maxTravelersPerReservation?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialTour
     */
    minTravelersPerTour?: number | null;
    /**
     * 
     * @type {CutOffTime}
     * @memberof PartialTour
     */
    cutOffTime?: CutOffTime;
    /**
     * 
     * @type {string}
     * @memberof PartialTour
     */
    customDomain?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialTour
     */
    seoPaths?: Array<string>;
    /**
     * 
     * @type {Array<Extra>}
     * @memberof PartialTour
     */
    extras?: Array<Extra>;
    /**
     * 
     * @type {Currency}
     * @memberof PartialTour
     */
    currency?: Currency;
    /**
     * 
     * @type {SelfService}
     * @memberof PartialTour
     */
    selfService?: SelfService;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof PartialTour
     */
    createAt?: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof PartialTour
     */
    updateAt?: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {string}
     * @memberof PartialTour
     */
    updateBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialTour
     */
    isDeleted?: boolean;
}

/**
 * Check if a given object implements the PartialTour interface.
 */
export function instanceOfPartialTour(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartialTourFromJSON(json: any): PartialTour {
    return PartialTourFromJSONTyped(json, false);
}

export function PartialTourFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialTour {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'meeting': !exists(json, 'meeting') ? undefined : PartialTourMeetingFromJSON(json['meeting']),
        'dropOff': !exists(json, 'dropOff') ? undefined : PartialTourDropOffFromJSON(json['dropOff']),
        'productContent': !exists(json, 'productContent') ? undefined : ProductContentFromJSON(json['productContent']),
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(OptionFromJSON)),
        'travelersFields': !exists(json, 'travelersFields') ? undefined : TravelersFieldsFromJSON(json['travelersFields']),
        'maxTravelersPerTour': !exists(json, 'maxTravelersPerTour') ? undefined : json['maxTravelersPerTour'],
        'maxTravelersPerReservation': !exists(json, 'maxTravelersPerReservation') ? undefined : json['maxTravelersPerReservation'],
        'minTravelersPerTour': !exists(json, 'minTravelersPerTour') ? undefined : json['minTravelersPerTour'],
        'cutOffTime': !exists(json, 'cutOffTime') ? undefined : CutOffTimeFromJSON(json['cutOffTime']),
        'customDomain': !exists(json, 'customDomain') ? undefined : json['customDomain'],
        'seoPaths': !exists(json, 'seoPaths') ? undefined : json['seoPaths'],
        'extras': !exists(json, 'extras') ? undefined : ((json['extras'] as Array<any>).map(ExtraFromJSON)),
        'currency': !exists(json, 'currency') ? undefined : CurrencyFromJSON(json['currency']),
        'selfService': !exists(json, 'selfService') ? undefined : SelfServiceFromJSON(json['selfService']),
        'createAt': !exists(json, 'createAt') ? undefined : FirebaseFirestoreTimestampFromJSON(json['createAt']),
        'updateAt': !exists(json, 'updateAt') ? undefined : FirebaseFirestoreTimestampFromJSON(json['updateAt']),
        'updateBy': !exists(json, 'updateBy') ? undefined : json['updateBy'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function PartialTourToJSON(value?: PartialTour | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operatorId': value.operatorId,
        'id': value.id,
        'active': value.active,
        'meeting': PartialTourMeetingToJSON(value.meeting),
        'dropOff': PartialTourDropOffToJSON(value.dropOff),
        'productContent': ProductContentToJSON(value.productContent),
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(OptionToJSON)),
        'travelersFields': TravelersFieldsToJSON(value.travelersFields),
        'maxTravelersPerTour': value.maxTravelersPerTour,
        'maxTravelersPerReservation': value.maxTravelersPerReservation,
        'minTravelersPerTour': value.minTravelersPerTour,
        'cutOffTime': CutOffTimeToJSON(value.cutOffTime),
        'customDomain': value.customDomain,
        'seoPaths': value.seoPaths,
        'extras': value.extras === undefined ? undefined : ((value.extras as Array<any>).map(ExtraToJSON)),
        'currency': CurrencyToJSON(value.currency),
        'selfService': SelfServiceToJSON(value.selfService),
        'createAt': FirebaseFirestoreTimestampToJSON(value.createAt),
        'updateAt': FirebaseFirestoreTimestampToJSON(value.updateAt),
        'updateBy': value.updateBy,
        'isDeleted': value.isDeleted,
    };
}

