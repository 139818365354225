import { useState, useEffect, DependencyList, useCallback } from "react";
import {
  collection,
  FirestoreError,
  DocumentData,
  query,
  QueryConstraint,
  onSnapshot,
  getDocs,
  Unsubscribe,
} from "firebase/firestore";
import { firestore } from "../../firebase";

export type UseCollectionData<T> = {
  data: T[];
  loading: boolean;
  error: FirestoreError | unknown;
  run: (queryFilters: QueryConstraint[]) => Promise<T[]>;
};

export const useCollectionData = <T extends DocumentData>(
  queryType: "onSnapshot" | "getDocs",
  collectionName: string,
  queryFilters?: QueryConstraint[],
  dependencies: DependencyList = [],
  disabled = false
): UseCollectionData<T> => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<FirestoreError | unknown>(null);

  const run: (queryFilters: QueryConstraint[]) => Promise<T[]> = useCallback(
    async (queryFilters): Promise<T[]> => {
      setLoading(true);

      try {
        const docs = await getDocs(
          query(collection(firestore, collectionName), ...queryFilters)
        );
        const documents = docs.docs.map((doc) => doc.data() as T);
        setData(documents as T[]);
        setLoading(false);
        return documents;
      } catch (e) {
        setError(e);
        setLoading(false);
        return [];
      }
    },
    []
  );

  useEffect(() => {
    if (disabled || !queryFilters) {
      setLoading(false);
      return;
    }

    let unsubscribe: Unsubscribe;

    if (queryType === "onSnapshot") {
      setLoading(true);

      unsubscribe = onSnapshot(
        query(collection(firestore, collectionName), ...queryFilters),
        async (snapshot) => {
          const documents = snapshot.docs.map((doc) => doc.data() as T);
          setData(documents as T[]);
          setLoading(false);
        },
        (err) => {
          setError(err);
          setLoading(false);
        }
      );
    } else if (queryType === "getDocs") {
      (async () => {
        await run(queryFilters);
      })();
    }

    return () => unsubscribe && unsubscribe();
  }, [...dependencies, disabled]);

  return {
    data,
    loading,
    error,
    run,
  };
};
