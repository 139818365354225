import React, { memo } from "react";
import { Router } from "./router";
import { BrowserRouter } from "react-router-dom";
import { ScrollTop } from "./ScrollTop";
import {
  AuthenticationProvider,
  GlobalDataProvider,
  GlobalOperatorDataProvider,
  OfficeProvider,
  OperatorProvider,
  UTMProvider,
  VersionProvider,
  CardLinkPaymentNotificationInitializer,
  SearchResultsProvider,
  ReservationProvider,
  AntdInitializer,
  GoogleAnalyticsProvider,
  HeaderMetaInitializer,
  LanguageProvider,
  MomentInitializer,
  StyleThemeInitializer,
  DomainProvider,
  YupInitializer,
} from "./providers";

const App = () => {
  return (
    <BrowserRouter>
      <DomainProvider>
        <StyleThemeInitializer>
          <AntdInitializer>
            <LanguageProvider>
              <HeaderMetaInitializer />
              <MomentInitializer />
              <YupInitializer />
              <GlobalDataProvider>
                <AuthenticationProvider>
                  <OperatorProvider>
                    <GlobalOperatorDataProvider>
                      <GoogleAnalyticsProvider>
                        <OfficeProvider>
                          <ReservationProvider>
                            <SearchResultsProvider>
                              <UTMProvider>
                                <VersionProvider>
                                  <CardLinkPaymentNotificationInitializer>
                                    <ScrollTop>
                                      <Router />
                                    </ScrollTop>
                                  </CardLinkPaymentNotificationInitializer>
                                </VersionProvider>
                              </UTMProvider>
                            </SearchResultsProvider>
                          </ReservationProvider>
                        </OfficeProvider>
                      </GoogleAnalyticsProvider>
                    </GlobalOperatorDataProvider>
                  </OperatorProvider>
                </AuthenticationProvider>
              </GlobalDataProvider>
            </LanguageProvider>
          </AntdInitializer>
        </StyleThemeInitializer>
      </DomainProvider>
    </BrowserRouter>
  );
};

export default memo(App);
