/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReservationUtm
 */
export interface ReservationUtm {
    /**
     * 
     * @type {string}
     * @memberof ReservationUtm
     */
    campaign: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUtm
     */
    source: string | null;
}

/**
 * Check if a given object implements the ReservationUtm interface.
 */
export function instanceOfReservationUtm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "campaign" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function ReservationUtmFromJSON(json: any): ReservationUtm {
    return ReservationUtmFromJSONTyped(json, false);
}

export function ReservationUtmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationUtm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaign': json['campaign'],
        'source': json['source'],
    };
}

export function ReservationUtmToJSON(value?: ReservationUtm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaign': value.campaign,
        'source': value.source,
    };
}

