import React from "react";
import { Modal as AntdModal } from "antd";

interface Props {
  closable?: boolean;
  open: boolean;
  title?: string;
  centered?: boolean;
  footer?: JSX.Element | null;
  onCancel?: () => void;
  children: React.ReactNode;
  width?: React.CSSProperties["width"];
  destroyOnClose?: boolean;
}

export const Modal = ({
  closable = false,
  centered = true,
  footer = null,
  children,
  width,
  open,
  title,
  onCancel,
  destroyOnClose,
}: Props): JSX.Element => (
  <AntdModal
    closable={closable}
    centered={centered}
    footer={footer}
    width={width}
    open={open}
    title={title}
    onCancel={onCancel}
    destroyOnClose={destroyOnClose}
  >
    {children}
  </AntdModal>
);
