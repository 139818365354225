/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostCancelReservationBody
 */
export interface PostCancelReservationBody {
    /**
     * 
     * @type {boolean}
     * @memberof PostCancelReservationBody
     */
    sendEmail: boolean;
}

/**
 * Check if a given object implements the PostCancelReservationBody interface.
 */
export function instanceOfPostCancelReservationBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sendEmail" in value;

    return isInstance;
}

export function PostCancelReservationBodyFromJSON(json: any): PostCancelReservationBody {
    return PostCancelReservationBodyFromJSONTyped(json, false);
}

export function PostCancelReservationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCancelReservationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sendEmail': json['sendEmail'],
    };
}

export function PostCancelReservationBodyToJSON(value?: PostCancelReservationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sendEmail': value.sendEmail,
    };
}

