import { lazy } from "react";

const OperatorsIntegration = lazy(() => import("./operators"));
const OperatorIntegration = lazy(() => import("./operators/operatorId"));
const ToursIntegration = lazy(() => import("./operators/operatorId/tours"));
const TourIntegration = lazy(
  () => import("./operators/operatorId/tours/_tourId")
);
const DefaultRolesAclsIntegration = lazy(() => import("./default-roles-acls"));
const RoleAclIntegration = lazy(
  () => import("./default-roles-acls/_roleAclsId")
);
const UsersIntegration = lazy(() => import("./users"));
const UserIntegration = lazy(() => import("./users/_userId"));
const ReservationsIntegration = lazy(() => import("./reservations"));
const ReservationsByOperatorIntegration = lazy(
  () => import("./operators/operatorId/reservations")
);
const CountriesIntegration = lazy(() => import("./countries"));
const CountryIntegration = lazy(() => import("./countries/_countryId"));
const CitiesIntegration = lazy(() => import("./countries/_countryId/cities"));
const CityIntegration = lazy(
  () => import("./countries/_countryId/cities/_cityId")
);
const AgenciesIntegration = lazy(() => import("./agencies"));
const AgencyIntegration = lazy(() => import("./agencies/_agencyId"));
const OfficesIntegration = lazy(() => import("./agencies/_agencyId/offices"));
const OfficeIntegration = lazy(
  () => import("./agencies/_agencyId/offices/_officeId")
);
const DiscountsIntegration = lazy(() => import("./discounts"));
const DiscountIntegration = lazy(() => import("./discounts/_discountId"));
const ManageToursAvailability = lazy(
  () => import("./operators/operatorId/manage-tours-availability")
);
const ManageOperatorsAvailabilityIntegration = lazy(
  () => import("./manage-operators-availability")
);
const OptionsAvailabilityIntegration = lazy(
  () => import("./operators/operatorId/options-availability")
);
const PickupLocationsIntegration = lazy(() => import("./pickup-locations"));
const ActivitiesIntegration = lazy(() => import("./activities"));
const ActivityIntegration = lazy(() => import("./activities/_activityId"));
const TravelersIntegration = lazy(
  () => import("./operators/operatorId/travelers")
);
const TravelersPrintIntegration = lazy(
  () => import("./operators/operatorId/travelers/print")
);
const ReservationsSummaryIntegration = lazy(
  () => import("./operators/operatorId/reservations-summary")
);
const PrintReservationsSummaryIntegration = lazy(
  () => import("./operators/operatorId/reservations-summary/print")
);
const HostsIntegration = lazy(() => import("./hosts"));
const HostIntegration = lazy(() => import("./hosts/hostId"));
const CategoriesIntegration = lazy(() => import("./categories"));
const CategoryIntegration = lazy(() => import("./categories/categoryId"));

export {
  OperatorsIntegration,
  OperatorIntegration,
  ToursIntegration,
  TourIntegration,
  DefaultRolesAclsIntegration,
  RoleAclIntegration,
  UsersIntegration,
  UserIntegration,
  ReservationsIntegration,
  ReservationsByOperatorIntegration,
  CountriesIntegration,
  CountryIntegration,
  CitiesIntegration,
  CityIntegration,
  AgenciesIntegration,
  AgencyIntegration,
  OfficesIntegration,
  OfficeIntegration,
  DiscountsIntegration,
  DiscountIntegration,
  ActivitiesIntegration,
  ActivityIntegration,
  TravelersIntegration,
  TravelersPrintIntegration,
  ManageToursAvailability,
  ManageOperatorsAvailabilityIntegration,
  OptionsAvailabilityIntegration,
  ReservationsSummaryIntegration,
  PickupLocationsIntegration,
  HostsIntegration,
  HostIntegration,
  CategoriesIntegration,
  CategoryIntegration,
  PrintReservationsSummaryIntegration,
};
