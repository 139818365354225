import { useCallback, useEffect, useRef, useState } from "react";

type IntervalFunction = () => unknown | void;

export const useInterval = (
  callback: IntervalFunction,
  delaySec: number,
  isActiveDefault = false
) => {
  const savedCallback = useRef<IntervalFunction | null>(null);
  const [isActive, setIsActive] = useState(isActiveDefault);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () =>
      savedCallback.current !== null && savedCallback.current();

    if (isActive) {
      const id = setInterval(tick, delaySec * 1000);
      return () => clearInterval(id);
    }
  }, [delaySec, isActive]);

  const start = useCallback(() => setIsActive(true), []);

  const stop = useCallback(() => setIsActive(false), []);

  return { start, stop };
};
