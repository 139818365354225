import React from "react";
import styled, { css, CSSObject, useTheme } from "styled-components";
import {
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button as ButtonAntd, ButtonProps, Modal } from "antd";
import { tint } from "polished";

type Variant = "warning" | "info" | "error";

export interface ModalAlertProps {
  variant: Variant;
  open: boolean;
  zIndex?: number;
  children?: JSX.Element;
  icon?: JSX.Element;
  title?: string;
  description?: string;
  okButtonProps?: OkButtonProps;
}

interface OkButtonProps {
  loading?: boolean;
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

export const ModalAlert = ({
  variant,
  open,
  zIndex,
  children,
  icon,
  title,
  description,
  okButtonProps,
}: ModalAlertProps): JSX.Element => {
  const iconType: Record<Variant, JSX.Element> = {
    info: <FontAwesomeIcon icon={faInfoCircle} size="5x" />,
    warning: <FontAwesomeIcon icon={faExclamationCircle} size="5x" />,
    error: <FontAwesomeIcon icon={faTimesCircle} size="5x" />,
  };

  return (
    <ModalStyled
      open={open}
      footer={null}
      centered={true}
      variant={variant}
      zIndex={zIndex}
      closable={false}
    >
      <div className="modal-alert--border" />
      <div className="modal-alert--content">
        <div className="modal-alert--content--icon">
          {icon ? icon : iconType[variant]}
        </div>
        {title && <span className="modal-alert--content--title">{title}</span>}
        {description && (
          <span className="modal-alert--content--description">
            {description}
          </span>
        )}
        {children}
        {okButtonProps && (
          <div className="buttons-wrapper">
            <div className="buttons">
              {okButtonProps && (
                <Button
                  variant={variant}
                  style={{ width: "fit-content" }}
                  onClick={() => okButtonProps?.onClick()}
                  loading={okButtonProps.loading}
                  disabled={okButtonProps?.disabled}
                  size="large"
                >
                  {okButtonProps.text}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </ModalStyled>
  );
};

const variantBackground = (variant?: Variant) => {
  const theme = useTheme();

  switch (variant) {
    case "warning":
      return theme.colors.warning;
    case "error":
      return theme.colors.error;
    case "info":
      return theme.colors.info;
    default:
      return theme.colors.warning;
  }
};

interface ModalStyledProps {
  variant?: Variant;
}

const ModalStyled = styled(Modal)<ModalStyledProps>`
  ${({ variant, theme }) => css`
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;

    .ant-modal-content {
      position: relative;
      overflow: hidden;
      border-radius: ${theme.border_radius.large};
    }

    .modal-alert--border {
      background: ${variantBackground(variant)};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 10px;
    }

    .modal-alert--content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${theme.paddings.medium};
      align-items: center;

      &--icon {
        margin: 0.5rem 0;
        color: ${variantBackground(variant)};
      }

      &--title {
        text-align: center;
        font-weight: ${theme.font_weight.medium};
        font-size: ${theme.font_sizes.large};
      }

      &--description {
        text-align: center;
        font-weight: ${theme.font_weight.small};
        font-size: ${theme.font_sizes.small};
      }

      .buttons-wrapper {
        margin-top: ${theme.paddings.small};
      }
    }
  `}
`;

const Button = ({
  variant,
  children,
  onClick,
  size,
  style,
  loading,
  disabled,
}: ButtonStyledProps): JSX.Element => (
  <ButtonStyled
    onClick={onClick}
    size={size}
    variant={variant}
    style={style}
    loading={loading}
    disabled={disabled}
  >
    {children}
  </ButtonStyled>
);

type PickButtonProps = "children" | "onClick";

interface ButtonStyledProps extends Pick<ButtonProps, PickButtonProps> {
  variant?: Variant;
  size?: "small" | "middle" | "large";
  style?: CSSObject;
  loading?: boolean;
  disabled?: boolean;
}

interface ButtonStyled {
  variant?: Variant;
}

const ButtonStyled = styled(ButtonAntd)<ButtonStyled>`
  ${({ variant, theme }) => css`
    background: ${variantBackground(variant)};
    border-color: ${variantBackground(variant)};
    color: ${theme.colors.white};
    font-size: ${theme.font_sizes.medium};
    font-weight: ${theme.font_weight.large};

    &:hover,
    &:focus {
      background: ${tint(0.25, variantBackground(variant))};
      border-color: ${tint(0.25, variantBackground(variant))};
      color: ${theme.colors.white};
    }
  `}
`;
