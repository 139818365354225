import React, { useEffect, useState } from "react";
import { useAuthentication } from "../providers";
import { AclId } from "../data-list";

interface Props {
  children: React.ReactElement | React.ReactElement[];
  aclId: AclId;
  invert?: boolean;
}

export const Acl = ({ children, aclId, invert }: Props): React.ReactElement => {
  const { authUser } = useAuthentication();

  const [isAuthorized, setIsAuthorized] = useState<boolean>(true);

  const isValidAcl = (aclId: AclId): boolean =>
    authUser?.acls.some((acl) => acl === aclId) || false;

  useEffect(() => {
    const _isValidAcl = isValidAcl(aclId);

    setIsAuthorized(invert ? !_isValidAcl : _isValidAcl);
  }, [location.pathname, authUser?.acls.length]);

  return isAuthorized ? <>{children}</> : <></>;
};
