import React, { useEffect } from "react";
import { useCollectionData } from "../hooks/firebase";
import { notification } from "../components";
import { useAuthentication } from "./AuthenticationProvider";
import { fetchReservation } from "../firebase/collections";
import assert from "assert";
import { isEmpty, toUpper } from "lodash";
import { fullName, titleize } from "../utils";
import styled from "styled-components";
import moment from "moment";
import { limit, orderBy, Timestamp, where } from "firebase/firestore";

interface Props {
  children: JSX.Element;
}

const now = moment().subtract(10, "minute").toDate();

export const CardLinkPaymentNotificationInitializer = ({
  children,
}: Props): JSX.Element => {
  const { authUser } = useAuthentication();

  const { data: payments, error: paymentsError } = useCollectionData<Payment>(
    "onSnapshot",
    "payments",
    [
      where("notification.response.object", "==", "checkout.session"),
      where("user.id", "==", authUser?.id),
      where("createAt", ">=", Timestamp.fromDate(now)),
      orderBy("createAt", "desc"),
      limit(1),
    ],
    [authUser?.id],
    !authUser?.id
  );

  useEffect(() => {
    paymentsError && notification({ type: "error" });
  }, [paymentsError]);

  useEffect(() => {
    if (isEmpty(payments)) return;

    void newPayment(payments[0]);
  }, [payments[0]?.id]);

  const newPayment = async (payment: Payment) => {
    const reservation = await fetchReservation(payment.reservationId);
    assert(reservation, "Missing reservation!");
    assert(reservation.contact, "Missing reservation.contact!");

    notification({
      type: "info",
      title: "New payment",
      description: (
        <ContainerNotification>
          <div className="tour-name">
            <span>
              {toUpper(reservation.tour.productContent?.productSetup.name.en)}
            </span>
          </div>
          <div className="contact">{titleize(fullName(reservation))}</div>
          <div className="amount">
            <span>
              {payment.currencyCode} {payment.amount}
            </span>
          </div>
        </ContainerNotification>
      ),
      placement: "topRight",
    });
  };

  return <React.Fragment>{children}</React.Fragment>;
};

const ContainerNotification = styled.div`
  margin-top: 0.6rem;

  .tour-name {
    display: flex;
    margin-bottom: 0.3rem;

    span {
      width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .amount {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 700;
  }
`;
