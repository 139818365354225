import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthentication, useLanguage } from "../providers";
import { AclId } from "../data-list";
import { Spinner } from "../components";

interface Props {
  aclId: AclId;
  children: React.ReactElement;
}

export const PrivateRoute = ({
  aclId,
  children,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { authUser } = useAuthentication();
  const { currentLanguageCode } = useLanguage();

  const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigateTo403 = () => navigate("/403");

  const isAuthorizedRoute = (acls: string[], aclId: string): boolean =>
    acls.some((acl) => acl === aclId);

  useEffect(() => {
    if (!authUser?.acls.length) {
      setIsAuthorized(false);
      setIsLoading(false);
      return;
    }

    const _isAuthorized = isAuthorizedRoute(authUser.acls, aclId);

    !_isAuthorized && navigateTo403();

    setIsAuthorized(_isAuthorized);
    setIsLoading(false);
  }, [location.pathname, authUser?.acls.length]);

  if (isLoading) return <Spinner />;

  return isAuthorized ? (
    children
  ) : (
    <Navigate to={`/${currentLanguageCode}/login`} />
  );
};
