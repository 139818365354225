import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthentication } from "./AuthenticationProvider";
import { useAcl } from "../hooks";
import { Button, Modal, Select, SelectOption, Title } from "../components";
import { Space } from "antd";
import { useCollectionData } from "../hooks/firebase";
import { where } from "firebase/firestore";

interface Context {
  currentOffice: Office | null;
  onChangeOffice: (officeId: string) => void;
}

const OfficeContext = createContext<Context>({
  currentOffice: null,
  onChangeOffice: console.info,
});

interface Props {
  children: React.ReactElement;
}

export const OfficeProvider = ({ children }: Props): React.ReactElement => {
  const [currentOffice, setCurrentOffice] = useState<Context["currentOffice"]>(
    null
  );
  const [currentOfficeId, setCurrentOfficeId] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { authUser } = useAuthentication();
  const { aclCheck } = useAcl();

  const { data: offices, loading: officesLoading } = useCollectionData<Office>(
    "onSnapshot",
    "offices",
    [
      where("isDeleted", "==", false),
      where("agencyId", "==", authUser?.agency?.id),
      where("cityId", "==", authUser?.city?.id),
    ],
    [authUser?.agency?.id, authUser?.city?.id],
    !authUser?.agency?.id || !authUser?.city?.id
  );

  useEffect(() => {
    if (!currentOffice && authUser && offices.length > 0)
      initializeCurrentOffice(offices);
  }, [authUser, officesLoading, currentOffice]);

  const initializeCurrentOffice = (offices: Office[]) => {
    setCurrentOffice(null);

    if (offices.length === 1) return setCurrentOffice(offices[0]);

    if (!aclCheck("selectOfficeInProvider")) return;

    setIsModalVisible(true);
  };

  const onChangeOffice: Context["onChangeOffice"] = (officeId) => {
    const office = offices.find((office) => office.id === officeId);

    if (office) setCurrentOffice(office);
  };

  const onClickConfirm = (): void => {
    if (currentOfficeId) {
      onChangeOffice(currentOfficeId);
      setIsModalVisible(false);
      setCurrentOfficeId(undefined);
    }
  };

  return (
    <OfficeContext.Provider value={{ currentOffice, onChangeOffice }}>
      <Modal open={isModalVisible} closable={false}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Title level={2} align="center">
            {authUser?.agency?.name}
          </Title>
          <Title level={3} align="center">
            Select your office
          </Title>
          {authUser && (
            <Select
              label="Office"
              value={currentOfficeId}
              onChange={(value) => setCurrentOfficeId(value)}
            >
              {offices.map((office) => (
                <SelectOption key={office.id} value={office.id}>
                  {office.name}
                </SelectOption>
              ))}
            </Select>
          )}
          <Button onClick={onClickConfirm} block type="primary">
            Confirm
          </Button>
        </Space>
      </Modal>
      {children}
    </OfficeContext.Provider>
  );
};

export const useOffice = (): Context => useContext(OfficeContext);
