import React, { useEffect, useState } from "react";
import "../styles/fonts/index.css";
// import "react-virtualized/styles.css";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { GlobalStyle } from "../styles/themes";
import { useDomainProvider } from "./DomainProvider";

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

export const StyleThemeInitializer = ({
  children,
}: Props): React.ReactElement => {
  const [styleTheme, setStyleTheme] = useState<DefaultTheme>();

  const { domain } = useDomainProvider();

  useEffect(() => {
    (async () =>
      setStyleTheme(await import(`../styles/themes/theme-${domain}.json`)))();
  }, []);

  if (!styleTheme) return <React.Fragment />;

  return (
    <ThemeProvider theme={styleTheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
