import { config } from "./config";
import { getEnvironment } from "../utils";

const currentEnvironment = getEnvironment(window.location.hostname);

console.log(currentEnvironment);

const currentConfig = config[currentEnvironment];

const {
  googleAnalytics,
  defaultTheme,
  reservationsResultsLimit,
} = config.common;

export {
  currentEnvironment,
  currentConfig,
  googleAnalytics,
  defaultTheme,
  reservationsResultsLimit,
};
