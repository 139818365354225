/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TravelerWithReservation } from './TravelerWithReservation';
import {
    TravelerWithReservationFromJSON,
    TravelerWithReservationFromJSONTyped,
    TravelerWithReservationToJSON,
} from './TravelerWithReservation';

/**
 * 
 * @export
 * @interface TravelersGroupByExtra
 */
export interface TravelersGroupByExtra {
    /**
     * 
     * @type {string}
     * @memberof TravelersGroupByExtra
     */
    extraName: string;
    /**
     * 
     * @type {Array<TravelerWithReservation>}
     * @memberof TravelersGroupByExtra
     */
    travelersWithReservations: Array<TravelerWithReservation>;
}

/**
 * Check if a given object implements the TravelersGroupByExtra interface.
 */
export function instanceOfTravelersGroupByExtra(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "extraName" in value;
    isInstance = isInstance && "travelersWithReservations" in value;

    return isInstance;
}

export function TravelersGroupByExtraFromJSON(json: any): TravelersGroupByExtra {
    return TravelersGroupByExtraFromJSONTyped(json, false);
}

export function TravelersGroupByExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelersGroupByExtra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'extraName': json['extraName'],
        'travelersWithReservations': ((json['travelersWithReservations'] as Array<any>).map(TravelerWithReservationFromJSON)),
    };
}

export function TravelersGroupByExtraToJSON(value?: TravelersGroupByExtra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'extraName': value.extraName,
        'travelersWithReservations': ((value.travelersWithReservations as Array<any>).map(TravelerWithReservationToJSON)),
    };
}

