import React, { createContext, useContext } from "react";

interface Props {
  children: React.ReactElement;
}

interface Context extends DomainAndSubdomain {
  isDefaultDomain: boolean;
}

interface DomainAndSubdomain {
  domain: Domain;
  subdomain: Subdomain;
}

const DEFAULT_DOMAIN: Domain = "findalocaltour";
const DEFAULT_SUBDOMAIN: Subdomain = "www";

export const DomainProviderContext = createContext<Context>({
  domain: DEFAULT_DOMAIN,
  subdomain: DEFAULT_SUBDOMAIN,
  isDefaultDomain: false,
});

export const useDomainProvider = (): Context =>
  useContext(DomainProviderContext);

export const DomainProvider = ({ children }: Props): JSX.Element => {
  const hostName = window.location.hostname;

  const { domain, subdomain } = getDomainAndSubdomain(hostName);

  const isDefaultDomain = DEFAULT_DOMAIN === domain;

  return (
    <DomainProviderContext.Provider
      value={{ isDefaultDomain, domain, subdomain }}
    >
      {children}
    </DomainProviderContext.Provider>
  );
};

const getQueryParam = <T extends string>(key: string): T | null => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(key) as T | null;
};

const getDomainAndSubdomain = (hostName: string): DomainAndSubdomain => {
  if (hostName === "localhost") {
    return {
      domain: getQueryParam<Domain>("theme") || DEFAULT_DOMAIN,
      subdomain: getQueryParam<Subdomain>("subdomain") || DEFAULT_SUBDOMAIN,
    };
  }

  // www.findalocaltour.com
  const [subdomain, theme] = hostName.split(".");

  return {
    domain: theme as Domain,
    subdomain: subdomain as Subdomain,
  };
};
