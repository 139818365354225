import React, { lazy, useMemo } from "react";

type ImportComponent<T extends ObjectType> = () => Promise<{
  default: React.ComponentType<T>;
}>;

type LazyComponent<T extends ObjectType> = React.LazyExoticComponent<
  React.ComponentType<T>
>;

export const useLazyComponent = <T extends ObjectType>(
  importComponent: ImportComponent<T>,
  defaultImportComponent: ImportComponent<T> | "empty",
  deps: React.DependencyList
): LazyComponent<T> => {
  const getLazyComponent = (): LazyComponent<T> =>
    lazy(async () => {
      try {
        return await importComponent();
      } catch (e) {
        if (defaultImportComponent !== "empty") {
          try {
            return await defaultImportComponent();
          } catch (e) {
            return { default: EmptyComponent };
          }
        } else {
          return { default: EmptyComponent };
        }
      }
    });

  return useMemo(getLazyComponent, [...deps]);
};

const EmptyComponent: React.FC = () => <React.Fragment />;
