/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HostColors
 */
export interface HostColors {
    /**
     * 
     * @type {string}
     * @memberof HostColors
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof HostColors
     */
    textColor?: string;
}

/**
 * Check if a given object implements the HostColors interface.
 */
export function instanceOfHostColors(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HostColorsFromJSON(json: any): HostColors {
    return HostColorsFromJSONTyped(json, false);
}

export function HostColorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostColors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backgroundColor': !exists(json, 'backgroundColor') ? undefined : json['backgroundColor'],
        'textColor': !exists(json, 'textColor') ? undefined : json['textColor'],
    };
}

export function HostColorsToJSON(value?: HostColors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backgroundColor': value.backgroundColor,
        'textColor': value.textColor,
    };
}

