/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Billing } from './Billing';
import {
    BillingFromJSON,
    BillingFromJSONTyped,
    BillingToJSON,
} from './Billing';
import type { BillingCardFee } from './BillingCardFee';
import {
    BillingCardFeeFromJSON,
    BillingCardFeeFromJSONTyped,
    BillingCardFeeToJSON,
} from './BillingCardFee';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';
import type { Einvoicing } from './Einvoicing';
import {
    EinvoicingFromJSON,
    EinvoicingFromJSONTyped,
    EinvoicingToJSON,
} from './Einvoicing';
import type { EmailSent } from './EmailSent';
import {
    EmailSentFromJSON,
    EmailSentFromJSONTyped,
    EmailSentToJSON,
} from './EmailSent';
import type { Extra } from './Extra';
import {
    ExtraFromJSON,
    ExtraFromJSONTyped,
    ExtraToJSON,
} from './Extra';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';
import type { GeoLoc } from './GeoLoc';
import {
    GeoLocFromJSON,
    GeoLocFromJSONTyped,
    GeoLocToJSON,
} from './GeoLoc';
import type { LanguageCode } from './LanguageCode';
import {
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';
import type { OptionTimePricing } from './OptionTimePricing';
import {
    OptionTimePricingFromJSON,
    OptionTimePricingFromJSONTyped,
    OptionTimePricingToJSON,
} from './OptionTimePricing';
import type { PartialRecordAgeIdNumber } from './PartialRecordAgeIdNumber';
import {
    PartialRecordAgeIdNumberFromJSON,
    PartialRecordAgeIdNumberFromJSONTyped,
    PartialRecordAgeIdNumberToJSON,
} from './PartialRecordAgeIdNumber';
import type { PartialTour } from './PartialTour';
import {
    PartialTourFromJSON,
    PartialTourFromJSONTyped,
    PartialTourToJSON,
} from './PartialTour';
import type { Pickup } from './Pickup';
import {
    PickupFromJSON,
    PickupFromJSONTyped,
    PickupToJSON,
} from './Pickup';
import type { ReservationContact } from './ReservationContact';
import {
    ReservationContactFromJSON,
    ReservationContactFromJSONTyped,
    ReservationContactToJSON,
} from './ReservationContact';
import type { ReservationDiscount } from './ReservationDiscount';
import {
    ReservationDiscountFromJSON,
    ReservationDiscountFromJSONTyped,
    ReservationDiscountToJSON,
} from './ReservationDiscount';
import type { ReservationOffice } from './ReservationOffice';
import {
    ReservationOfficeFromJSON,
    ReservationOfficeFromJSONTyped,
    ReservationOfficeToJSON,
} from './ReservationOffice';
import type { ReservationStatus } from './ReservationStatus';
import {
    ReservationStatusFromJSON,
    ReservationStatusFromJSONTyped,
    ReservationStatusToJSON,
} from './ReservationStatus';
import type { ReservationUtm } from './ReservationUtm';
import {
    ReservationUtmFromJSON,
    ReservationUtmFromJSONTyped,
    ReservationUtmToJSON,
} from './ReservationUtm';
import type { Traveler } from './Traveler';
import {
    TravelerFromJSON,
    TravelerFromJSONTyped,
    TravelerToJSON,
} from './Traveler';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Reservation
 */
export interface Reservation {
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    id: string;
    /**
     * 
     * @type {Domain}
     * @memberof Reservation
     */
    theme: Domain;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof Reservation
     */
    createAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof Reservation
     */
    expiredAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof Reservation
     */
    bookAt?: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof Reservation
     */
    cutOffAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {Array<string>}
     * @memberof Reservation
     */
    search: Array<string>;
    /**
     * 
     * @type {LanguageCode}
     * @memberof Reservation
     */
    languageCode: LanguageCode;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof Reservation
     */
    status: ReservationStatus;
    /**
     * 
     * @type {PartialTour}
     * @memberof Reservation
     */
    tour: PartialTour;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    tourStartDate: string;
    /**
     * 
     * @type {PartialRecordAgeIdNumber}
     * @memberof Reservation
     */
    tourTravelersCount: PartialRecordAgeIdNumber;
    /**
     * 
     * @type {OptionTimePricing}
     * @memberof Reservation
     */
    tourOptionTimePricing: OptionTimePricing;
    /**
     * 
     * @type {Array<Traveler>}
     * @memberof Reservation
     */
    travelers: Array<Traveler>;
    /**
     * 
     * @type {ReservationContact}
     * @memberof Reservation
     */
    contact?: ReservationContact | null;
    /**
     * 
     * @type {ReservationDiscount}
     * @memberof Reservation
     */
    discount?: ReservationDiscount | null;
    /**
     * 
     * @type {Billing}
     * @memberof Reservation
     */
    billing: Billing;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    totalPaid?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    totalDue?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    totalRefund?: string;
    /**
     * 
     * @type {EmailSent}
     * @memberof Reservation
     */
    emailSentToCustomer?: EmailSent;
    /**
     * 
     * @type {Pickup}
     * @memberof Reservation
     */
    pickup?: Pickup;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    purchaseNumber?: string;
    /**
     * 
     * @type {User}
     * @memberof Reservation
     */
    user?: User | null;
    /**
     * 
     * @type {ReservationUtm}
     * @memberof Reservation
     */
    utm: ReservationUtm | null;
    /**
     * 
     * @type {boolean}
     * @memberof Reservation
     */
    acceptedTermsAndConditions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Reservation
     */
    acceptedChangeAndCancellationPolicies?: boolean;
    /**
     * 
     * @type {ReservationOffice}
     * @memberof Reservation
     */
    office: ReservationOffice | null;
    /**
     * 
     * @type {boolean}
     * @memberof Reservation
     */
    sendEmail: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Reservation
     */
    paymentsIds: Array<string>;
    /**
     * 
     * @type {Array<Extra>}
     * @memberof Reservation
     */
    extras?: Array<Extra>;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    dropOff?: string;
    /**
     * 
     * @type {Einvoicing}
     * @memberof Reservation
     */
    einvoicing?: Einvoicing;
    /**
     * 
     * @type {GeoLoc}
     * @memberof Reservation
     */
    geoLoc?: GeoLoc;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    splittedFromReservationId?: string;
    /**
     * 
     * @type {BillingCardFee}
     * @memberof Reservation
     */
    cardFee?: BillingCardFee | null;
}

/**
 * Check if a given object implements the Reservation interface.
 */
export function instanceOfReservation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "theme" in value;
    isInstance = isInstance && "createAt" in value;
    isInstance = isInstance && "expiredAt" in value;
    isInstance = isInstance && "cutOffAt" in value;
    isInstance = isInstance && "search" in value;
    isInstance = isInstance && "languageCode" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "tour" in value;
    isInstance = isInstance && "tourStartDate" in value;
    isInstance = isInstance && "tourTravelersCount" in value;
    isInstance = isInstance && "tourOptionTimePricing" in value;
    isInstance = isInstance && "travelers" in value;
    isInstance = isInstance && "billing" in value;
    isInstance = isInstance && "utm" in value;
    isInstance = isInstance && "office" in value;
    isInstance = isInstance && "sendEmail" in value;
    isInstance = isInstance && "paymentsIds" in value;

    return isInstance;
}

export function ReservationFromJSON(json: any): Reservation {
    return ReservationFromJSONTyped(json, false);
}

export function ReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'theme': DomainFromJSON(json['theme']),
        'createAt': FirebaseFirestoreTimestampFromJSON(json['createAt']),
        'expiredAt': FirebaseFirestoreTimestampFromJSON(json['expiredAt']),
        'bookAt': !exists(json, 'bookAt') ? undefined : FirebaseFirestoreTimestampFromJSON(json['bookAt']),
        'cutOffAt': FirebaseFirestoreTimestampFromJSON(json['cutOffAt']),
        'search': json['_search'],
        'languageCode': LanguageCodeFromJSON(json['languageCode']),
        'status': ReservationStatusFromJSON(json['status']),
        'tour': PartialTourFromJSON(json['tour']),
        'tourStartDate': json['tourStartDate'],
        'tourTravelersCount': PartialRecordAgeIdNumberFromJSON(json['tourTravelersCount']),
        'tourOptionTimePricing': OptionTimePricingFromJSON(json['tourOptionTimePricing']),
        'travelers': ((json['travelers'] as Array<any>).map(TravelerFromJSON)),
        'contact': !exists(json, 'contact') ? undefined : ReservationContactFromJSON(json['contact']),
        'discount': !exists(json, 'discount') ? undefined : ReservationDiscountFromJSON(json['discount']),
        'billing': BillingFromJSON(json['billing']),
        'totalPaid': !exists(json, 'totalPaid') ? undefined : json['totalPaid'],
        'totalDue': !exists(json, 'totalDue') ? undefined : json['totalDue'],
        'totalRefund': !exists(json, 'totalRefund') ? undefined : json['totalRefund'],
        'emailSentToCustomer': !exists(json, 'emailSentToCustomer') ? undefined : EmailSentFromJSON(json['emailSentToCustomer']),
        'pickup': !exists(json, 'pickup') ? undefined : PickupFromJSON(json['pickup']),
        'purchaseNumber': !exists(json, 'purchaseNumber') ? undefined : json['purchaseNumber'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'utm': ReservationUtmFromJSON(json['utm']),
        'acceptedTermsAndConditions': !exists(json, 'acceptedTermsAndConditions') ? undefined : json['acceptedTermsAndConditions'],
        'acceptedChangeAndCancellationPolicies': !exists(json, 'acceptedChangeAndCancellationPolicies') ? undefined : json['acceptedChangeAndCancellationPolicies'],
        'office': ReservationOfficeFromJSON(json['office']),
        'sendEmail': json['sendEmail'],
        'paymentsIds': json['paymentsIds'],
        'extras': !exists(json, 'extras') ? undefined : ((json['extras'] as Array<any>).map(ExtraFromJSON)),
        'dropOff': !exists(json, 'dropOff') ? undefined : json['dropOff'],
        'einvoicing': !exists(json, 'einvoicing') ? undefined : EinvoicingFromJSON(json['einvoicing']),
        'geoLoc': !exists(json, 'geoLoc') ? undefined : GeoLocFromJSON(json['geoLoc']),
        'splittedFromReservationId': !exists(json, 'splittedFromReservationId') ? undefined : json['splittedFromReservationId'],
        'cardFee': !exists(json, 'cardFee') ? undefined : BillingCardFeeFromJSON(json['cardFee']),
    };
}

export function ReservationToJSON(value?: Reservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'theme': DomainToJSON(value.theme),
        'createAt': FirebaseFirestoreTimestampToJSON(value.createAt),
        'expiredAt': FirebaseFirestoreTimestampToJSON(value.expiredAt),
        'bookAt': FirebaseFirestoreTimestampToJSON(value.bookAt),
        'cutOffAt': FirebaseFirestoreTimestampToJSON(value.cutOffAt),
        '_search': value.search,
        'languageCode': LanguageCodeToJSON(value.languageCode),
        'status': ReservationStatusToJSON(value.status),
        'tour': PartialTourToJSON(value.tour),
        'tourStartDate': value.tourStartDate,
        'tourTravelersCount': PartialRecordAgeIdNumberToJSON(value.tourTravelersCount),
        'tourOptionTimePricing': OptionTimePricingToJSON(value.tourOptionTimePricing),
        'travelers': ((value.travelers as Array<any>).map(TravelerToJSON)),
        'contact': ReservationContactToJSON(value.contact),
        'discount': ReservationDiscountToJSON(value.discount),
        'billing': BillingToJSON(value.billing),
        'totalPaid': value.totalPaid,
        'totalDue': value.totalDue,
        'totalRefund': value.totalRefund,
        'emailSentToCustomer': EmailSentToJSON(value.emailSentToCustomer),
        'pickup': PickupToJSON(value.pickup),
        'purchaseNumber': value.purchaseNumber,
        'user': UserToJSON(value.user),
        'utm': ReservationUtmToJSON(value.utm),
        'acceptedTermsAndConditions': value.acceptedTermsAndConditions,
        'acceptedChangeAndCancellationPolicies': value.acceptedChangeAndCancellationPolicies,
        'office': ReservationOfficeToJSON(value.office),
        'sendEmail': value.sendEmail,
        'paymentsIds': value.paymentsIds,
        'extras': value.extras === undefined ? undefined : ((value.extras as Array<any>).map(ExtraToJSON)),
        'dropOff': value.dropOff,
        'einvoicing': EinvoicingToJSON(value.einvoicing),
        'geoLoc': GeoLocToJSON(value.geoLoc),
        'splittedFromReservationId': value.splittedFromReservationId,
        'cardFee': BillingCardFeeToJSON(value.cardFee),
    };
}

